<script>
/* eslint-disable */
</script>
<template>
<v-app>
    <b-container>  
      <v-alert v-model="alert" color="pink" dark border="top" transition="scale-transition" dismissible>
          {{ alertText }}
      </v-alert>
          <b-row>
            <v-btn v-if="loggedUser.role == 'admin' || 
                         loggedUser.role == 'globalManager' || 
                         loggedUser.role == 'viceManager' || 
                         loggedUser.role == 'branchManager' || 
                         loggedUser.role == 'weiser'" 
              @click="timeClockDetails = true">Timeclock Details</v-btn>
          </b-row> 
          <b-row>
              <b-col cols="3" ><h2>{{ pinUser.first_name  }} - {{ pinUser.role }}</h2></b-col>
              <b-col cols="3">
                <ul id="display">
                    <li v-for="(num, index) in pinAst" :key="index">{{ num }}</li>
                    <div class="clear"></div>
                </ul>
              </b-col>
              <b-col cols="3">&nbsp;</b-col>
              <b-col cols="3">&nbsp;</b-col>
              <b-col cols="3">{{ timeNow }}</b-col>
          </b-row>
          
          <b-row>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('1')">1</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('2')">2</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('3')">3</div>
              <!-- &nbsp; -->
            </b-col>
            
            <b-col cols="2">
                <div class="inOut" v-if="pinUser.state == 'FINISH'" @click="timeFoo('in')">IN</div>
                <div class="inOut inactive" v-else @click="timeFoo('in')">IN</div>
            </b-col>
            <b-col cols="2">
                <div class="inOut" v-if="pinUser.state == 'IN' || pinUser.state == 'ENDBREAK'" @click="timeFoo('finish')">FINISH</div>
                <div class="inOut inactive" v-else @click="timeFoo('finish')">FINISH</div>
            </b-col>
            <b-col cols="2">&nbsp;</b-col>
          </b-row>
            <br />
          <b-row>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('4')">4</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('5')">5</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('6')">6</div>
              <!-- &nbsp; -->
            </b-col>
            
            <b-col cols="4">
                <div class="break" v-if="pinUser.state == 'IN' || pinUser.state == 'ENDBREAK'" @click="timeFoo('break')">Start Break</div>
                <div class="break inactive" v-else @click="timeFoo('break')">Start Break</div>
            </b-col>

            <b-col cols="2">&nbsp;</b-col>
          </b-row>
            <br />
          <b-row>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('7')">7</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('8')">8</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('9')">9</div>
              <!-- &nbsp; -->
            </b-col>

            <b-col cols="4">
                <div class="break" v-if="pinUser.state == 'BREAK'" @click="timeFoo('endbreak')">End break</div>
                <div class="break inactive" v-else @click="timeFoo('endbreak')">End break</div>
            </b-col>

            <b-col cols="2">&nbsp;</b-col>
          </b-row>
            <br />
          <b-row>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('clear')">C</div>
              <!-- &nbsp; -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('0')">0</div>
              <!-- &nbsp -->
            </b-col>
            <b-col cols="2" class="text-center" align-v="center">
              <div class="pinBtn" @click="pinCharClock('enter')">E</div>
            </b-col>
            <b-col cols="6">&nbsp;</b-col>
          </b-row>

      <v-dialog
        v-model="timeClockDetails"
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Timeclock Details</span>
            <export-excel
              class="btn btn-success"
              :data="detailedInfo"
              :fields="json_fields"
              worksheet="Worksheet"
            >
              <i class="mdi mdi-download"> Excel Export </i>
            </export-excel>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="2">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Select Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8">

                <template>
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            IN
                          </th>
                          <th class="text-left">
                            OUT
                          </th>
                          <th class="text-left">
                            Break
                          </th>
                          <th class="text-left">
                            Break Over
                          </th>
                          <th class="text-left">
                            Hours
                          </th>
                          <th class="text-left">
                            EDIT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in detailedInfo"
                          :key="index"
                        >
                          <td>{{ item.user_name }}</td>
                          <td>{{ item.startwork }}</td>
                          <td>{{ item.endwork }}</td>
                          <td>{{ (Number(item.breaktime)).toFixed(2) }}</td>
                          <td>{{ Number(item.breackover).toFixed(2) }}</td>
                          <td>{{ (Number(item.worktime) / 60).toFixed(2) }}</td>
                          <td @click="editTabel(item)" v-if="item.endwork == ''" class="inactive">Edit Item</td>
                          <td @click="editTabel(item)" v-else>Edit Item</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

    </b-container>

    <v-bottom-sheet v-model="sheet">
          <v-sheet
            class="text-center"
            height="200px"
          >
            <v-btn
              class="mt-6"
              text
              color="red"
              @click="sheet = !sheet"
            >
              close
            </v-btn>
            <div class="py-3">
              <h1>
                {{ errorText }}
              </h1>
            </div>
          </v-sheet>
        </v-bottom-sheet>

        <v-dialog
              v-model="managerPin"
              max-width="500px"
            >
              <v-card>
                
                <div class="container" style="width: 400px;" >
              
              <div class="row">

                  <div class="col-12" style="margin: auto">
                      <ul id="display">
                          <li v-for="(num, index) in pinAst" :key="index">{{ num }}</li>
                          <div class="clear"></div>
                      </ul>
                  </div>
              </div>
              <div class="row">
                  <div class="col pinBtn" @click="pinChar('1')">
                      1
                  </div>
                  <div class="col pinBtn" @click="pinChar('2')">
                      2
                  </div>
                  <div class="col pinBtn" @click="pinChar('3')">
                      3
                  </div>
              </div>
              <div class="row">
                  <div class="col pinBtn" @click="pinChar('4')">
                      4
                  </div>
                  <div class="col pinBtn" @click="pinChar('5')">
                      5
                  </div>
                  <div class="col pinBtn" @click="pinChar('6')">
                      6
                  </div>
              </div>
              <div class="row">
                  <div class="col pinBtn" @click="pinChar('7')">
                      7
                  </div>
                  <div class="col pinBtn" @click="pinChar('8')">
                      8
                  </div>
                  <div class="col pinBtn" @click="pinChar('9')">
                      9
                  </div>
              </div>
              <div class="row">
                  <div class="col pinBtn" @click="pinChar('clear')">
                      C
                  </div>
                  <div class="col pinBtn" @click="pinChar('0')">
                      0
                  </div>
                  <div class="col pinBtn green" @click="pinChar('enter')">
                      E
                  </div>
              </div>
          </div>
          
          </v-card>
        </v-dialog>

        <v-dialog
              v-model="managerEditModal"
              max-width="600px"
            >
          <v-card>
            
            <template>
                  <v-simple-table height="300px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            State
                          </th>
                          <th class="text-left">
                            Start Date
                          </th>
                          <th class="text-left">
                            Comment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in editList" :key="index">
                          <td>{{ item.user_name }}</td>
                          <td>{{ item.state }}</td>
                          <td>
                          <!-- <input type="text" class="form-control" :value="item.start_date" :v-model="item.start_date"> -->
                          <input type="text" class="form-control" v-model="item.start_date" placeholder="edit me" @keypress="isNumber($event)" />
                          </td>
                          <td><input type="text" class="form-control" placeholder="Comment" v-model="item.comment"></td>
                          <td><v-btn @click="editItemFinish(item)">Edit Item</v-btn></td>
                        </tr>
                        <tr><td>
                          <v-btn @click="closeEditModal">Close</v-btn>
                        </td></tr>
                      </tbody>
                      
                    </template>
                  </v-simple-table>
                </template>
          </v-card>
        </v-dialog>
</v-app>
</template>

<script>
/* eslint-disable */
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vue from "vue";
import excel from "vue-excel-export";
import axios from 'axios';

Vue.use(excel);


export default {
  name: "dashboard",
  components: {

  },
  data() {
    return {
      json_fields: {
          "User": "user_name",
          "IN": "startwork",
          "Finish": "endwork",
          "Work Time(Minutes)": "worktime",
          "Break Time(Minutes)": "breaktime",
          "Break Over(Minutes)": "breackover",
          "Created": "created_at",
      },
      managerPin: false,
      sheet: false,
      errorText: '',
      alertText: '',
      menu: false,
      detailedInfo: [],
      date: new Date(),
      timeClockDetails: false,
      loggedUser: {},
      loginActive: false,
      alert: false,
      enteredPin: '',
      pinDecon: ['-', '-', '-', '-', '-', '-'],
      pinAst: ['-', '-', '-', '-', '-', '-'],
      pinDeconMng: ['-', '-', '-', '-', '-', '-'],
      pinAstMng: ['-', '-', '-', '-', '-', '-'],
      loginToken: '',
      pinUser: {
          first_name: '',
          role: '',
      },
      enableManagerEdit: false,
      selectedTabel: {},
      managerEditModal: false,
      editList: [],
    };
  },
  mounted() {
        window.addEventListener("keypress", e=> {
          this.logKeyClock(e);
        });
  },
  computed: {
        pinSync(){
            return this.pinDecon;
        },
        timeNow () {
            return this.date;
        },
        filterDetails (){
          this.detailedInfo.forEach(x => {
              x.startwork = x.startwork
              x.endwork = x.endwork
          })
          return this.detailedInfo;
        }
    },
    beforeRouteEnter (to, from, next) {
    next(vm => {
       if (vm.$store.state.auth.user.data.role.toLowerCase() == "admin"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "vicemanager"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "globalmanager"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "branchmanager"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "weiser" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "cashier"
            || vm.$store.state.auth.user.data.role.toLowerCase() == "courier" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "posaccess" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "cook" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "thermometer" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "housekeeper" 
            || vm.$store.state.auth.user.data.role.toLowerCase() == "driver") {
         vm.$router.push({name: "timetable"}).catch(()=>{});
       }
       else {
         vm.$router.push({name: "dashboard"}).catch(()=>{});
       }
    });
  },
  mounted() {
    this.date = this.formatDate(this.date);
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.loggedUser = this.$store.state.auth.user.data;

    this.updateData(this.date)

    window.addEventListener("keypress", e => {
        this.logKeyClock(e);
        });
  },
  watch: {
    date(val){
      this.updateData(val);
    },
  },
  methods: {
      closeEditModal(){
        this.managerEditModal = false;
        this.editList = [];
      },
      isNumber(evt) {
            evt = (evt) ? evt : window.event;
            console.log(evt);
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 58) {
              evt.preventDefault();         

            }  
        },
        editItemFinish(item){
            if(item.comment != ''){
              const TOKEN = this.loggedUser.token;
              var bodyFormData = new FormData();
              bodyFormData.set("id", item.id);
              bodyFormData.set("start_date", item.start_date);
              bodyFormData.set("comment", item.comment);
              // bodyFormData.set("order_id", this.order.orderId);
              axios
                .request({
                  method: "post",
                  url:
                    this.$hostname + "tabel/edit",
                  headers: {
                    Authorization: "Bearer " + TOKEN,
                  },
                  data: bodyFormData,
                })
                .then((response) => {
                  // this.getList(this.selectedTabel);
                  this.errorText = response.data.data;
                  this.sheet = true;
                  this.updateData(this.date);
                  this.$forceUpdate();
                });

            } else {
              this.errorText = "Comment is a must for editing tabel item";
              this.sheet = true;
            }

        },
        editTabel(item){
          this.selectedTabel = item;
          this.managerPin = true;
        },
        checkManager(pin){
          this.managerPin = true;
          const TOKEN = this.loggedUser.token;
          var bodyFormData = new FormData();
          bodyFormData.set("pin", pin);
          // bodyFormData.set("order_id", this.order.orderId);
          axios
            .request({
              method: "post",
              url:
                this.$hostname + "manager/check-pin",
              headers: {
                Authorization: "Bearer " + TOKEN,
              },
              data: bodyFormData,
            })
            .then((response) => {
    
                if (response.data.data == true)
                {
                  this.enableManagerEdit = true;
                  this.managerPin = false;
                  this.managerEditModal = true;
                  this.getList(this.selectedTabel);
                  return true;
                }
                else{
                  this.enableManagerEdit = false;
                  return false;
                }
            });
        },
        getList(item){
          const TOKEN = this.loggedUser.token;
          var bodyFormData = new FormData();
          bodyFormData.set("id", item.id);
          axios
              .request({
                method: "post",
                url:
                  this.$hostname + "tabel/list",
                headers: {
                  Authorization: "Bearer " + TOKEN,
                },
                data: bodyFormData,
              })
              .then((response) => {
                this.editList = response.data.data;
              });
        },
        logKey(e) {
          if(this.managerPin){
            e=e || window.event;
            var charCode=(e.which) ? e.which: e.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !==46) {
              e.preventDefault();
            }

            else {
              return this.pinChar(e.key);
            }
          }
        },
        pinChar(char) {
          if(char==='clear') {
            this.pinDecon=['-','-','-','-','-','-'];
            this.pinAst=['-','-','-','-','-','-'];
            this.enteredPin='';
          }
          else if(char==='enter') {
            alert(this.enteredPin);
          }
          else {
            if(this.enteredPin.length === 5) {
              var index=this.pinDecon.indexOf('-');
              this.pinDecon[index]=char;
              this.pinAst[index]='*';
              this.enteredPin=this.enteredPin+char;
              this.checkManager(this.enteredPin);
              this.pinDecon=['-',
              '-',
              '-',
              '-',
              '-',
              '-'];
              this.pinAst=['-',
              '-',
              '-',
              '-',
              '-',
              '-'];
              this.enteredPin='';
            }
            else {
                var index=this.pinDecon.indexOf('-');
                this.pinDecon[index]=char;
                this.pinAst[index]="*";
                this.enteredPin=this.enteredPin+char;
                this.$forceUpdate();
            }
          }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        
        updateData(date){
          const TOKEN = this.loggedUser.token;
          var bodyUpdate = new FormData();
          bodyUpdate.set("date", date);

          axios
            .request({
              method: "post",
              url:
                this.$hostname + "tabel/history",
              headers: {
                Authorization: "Bearer " + TOKEN,
              },
              data: bodyUpdate,
            })
            .then((response) => {
              this.detailedInfo = response.data.data;
          });  
          this.$forceUpdate();
        },
        logKeyClock(e) {
          if(!this.managerEditModal){
            e=e || window.event;
            var charCode=(e.which) ? e.which: e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !==46) {
                evt.preventDefault();
            }
            else {
              if(!this.managerPin) {
                return this.pinCharClock(e.key);
              } else {
                return this.pinChar(e.key);
              }
            }
          }
        },
        timeFoo(state){
            if(this.loginActive){
              if(state === 'in'){
                  this.sendTimesheet(state);
              }
              else if (state === 'finish'){
                  this.sendTimesheet(state);
              }
              else if (state === 'break'){
                  this.sendTimesheet(state);
              }
              else if (state === 'endbreak'){
                  this.sendTimesheet(state);
              }
              this.updateData(this.date);
            }
            else {
              alert("User isn't logged in");
            }
        },
        sendTimesheet(state){
            var TOKEN = this.loginToken;
            var bodyFormData = new FormData();
            //bodyFormData.set("branch", this.branch);
            bodyFormData.set("state", state);
            axios
            .request({
                method: "post",
                url: this.$hostname + "tabel/start",
                headers: {
                Authorization: "Bearer " + TOKEN,
              },
              data: bodyFormData,
            })
            .then((response) => {
                console.log('responese: ',response)
                this.errorText = response.data.data;
                this.sheet = true;
            });
            this.pinUser = {
                first_name: '',
                role: '',
            };
            this.updateData(this.date);
            this.loginActive = false;
        },
        correctionFoo(){
            alert('Correction Function');
        },
        exitFoo(){
            alert('Exit Function');
        },
        pinCharClock(char){
                this.alert = false;
                if(char === 'clear'){
                    // alert('Input Cleared');
                    this.pinDecon = ['-', '-', '-', '-', '-', '-'];
                    this.pinAst = ['-', '-', '-', '-', '-', '-'];
                    this.enteredPin = '';
                    this.pinUser = {};
                }
                else if(char === 'enter'){
                  this.loginClock(this.enteredPin);
                }
                else {
                    if(this.enteredPin.length === 5)
                    {
                        var index = this.pinDecon.indexOf('-');
                        this.pinDecon[index] = char;
                        this.pinAst[index] = '*';
                        this.enteredPin = this.enteredPin + char;
                        this.loginClock(this.enteredPin);
                        this.pinDecon = ['-', '-', '-', '-', '-', '-'];
                        this.pinAst = ['-', '-', '-', '-', '-', '-'];
                        this.enteredPin = '';
                    }
                    else {
                    var index = this.pinDecon.indexOf('-');
                    this.pinDecon[index] = char;
                    this.pinAst[index] = '*';
                    this.enteredPin = this.enteredPin + char;
                    this.$forceUpdate();
                    }
                }
            },
          loginClock(pin){
              var bodyFormData = new FormData();
              bodyFormData.set("pin", pin);
              bodyFormData.set("branch_id", this.loggedUser.branch_id);
              axios.request({
                  method: "post",
                  url:
                      this.$authHostName + "/clocked",
                  data: bodyFormData,
                  })
                  .then((response) => {
                    if(response.status === 200 && !response.data.is_error){
                      console.log('------', response);
                      this.loginToken = response.data.data.token;
                      this.pinUser = response.data.data;
                      this.loginActive = true;
                    }
                    else {
                        this.alertText = response.data.error_message;
                        this.alert = true;
                    }
                });
        },
    }
};
</script>
<style>
    .numChar {
    font-family: monospace;
    font-size: 200%;
    border-radius: 5%;
    padding: 15px 0px;
    height: 100%;
    width: 100%;
    background: #b1bdb8;
    color: rgb(255, 255, 255);
    cursor: pointer;
    }
    .inOut {
    font-family: monospace;
    font-size: 300%;
    border-radius: 5%;
    text-align: center;
    height: 100%;
    width: 100%;
    background: #18587e88;
    color: rgb(2, 1, 1);
    margin: 5px;
    cursor: pointer;
    }

    .break {
    font-family: monospace;
    font-size: 300%;
    border-radius: 5%;
    text-align: center;
    height: 100%;
    width: 100%;
    background: #00D881;
    color: rgb(2, 1, 1);
    margin: 5px;
    cursor: pointer;
    }

    .inactive {
      opacity: 0.5;
      pointer-events: none;
    } 
    
    .exit {
    font-family: monospace;
    font-size: 300%;
    border-radius: 5%;
    text-align: center;
    height: 100%;
    width: 100%;
    background: #d80036;
    color: rgb(2, 1, 1);
    margin: 5px;
    cursor: pointer;
    }

    .correction {
    font-family: monospace;
    font-size: 200%;
    text-align: center;
    height: 100%;
    width: 100%;
    background: #88928e;
    color: rgb(2, 1, 1);
    margin: 5px;
    cursor: pointer;
    }

    #display {
    position: relative;
    top: -10px;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #00D881;
    }

    #display li {
    display: inline-block;
    font-family: monospace;
    font-size: 200%;
    padding: 5px 12px;
    background: #FDD147;
    color: black;
    margin-right: 1px;
    }
</style>
